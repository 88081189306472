import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import backgroundMatrix from 'assets/img/matrix.jpg';

class Header extends React.Component {
  render() {
    return (
      <>
        <div style={{ backgroundImage: `url(${backgroundMatrix})` }} className="header header-matrix pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
