import React, { useState, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';

import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';

import Header from "components/Header";
import Loading from "components/Loading";
import CustomPagination from "components/CustomPagination";
import JsonViewer from "components/JsonViewer";

import api from "config/api";

import "./styles.scss";

function Logs() {
    const limit = 10;
    const history = useHistory();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const { collection } = useParams();

    useEffect(() => {
        fetchLogs();
    }, [page]);

    async function fetchLogs() {
        setLoading(true);
        try {
            const { data } = await api.get(`/log?nameCollection=${collection}&offset=${page - 1}`);

            setLogs(data.data);
            setTotal(data.pagination.total);
        } catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
                history.push('/');
            } else {
                console.log(error.response.data.message)
            }
        }
        setLoading(false);
    }

    return (
        <>
            <Header />
            {loading && <Loading />}
            {/* Page content */}
            <Container className="mt--7 bg-dark" fluid>
                <Row>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="bg-gradient-dark border border-success">
                            <CardHeader className="bg-transparent text-white">
                                {collection}
                                <br />
                                <br />
                                <Link className="back-link" to="/admin/index">
                                    <i className="fa fa-arrow-left" /> Voltar
                                </Link>
                                <Button
                                    onClick={() => fetchLogs()}
                                    className="float-md-right my-2"
                                    color="success"
                                >
                                    <i class="fas fa-redo-alt"></i>
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <div className="logs-list" style={{ color: "#3ddc51" }}>
                                    {logs && logs.map(log => (
                                        <JsonViewer key={log._id} json={log} />
                                    ))}
                                </div>
                                <div className="m-4 d-flex justify-content-center">
                                    <CustomPagination
                                        total={total}
                                        page={page}
                                        length={logs.length}
                                        limit={limit}
                                        setPage={setPage}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Logs;