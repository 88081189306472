/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

import "../../styles.scss"

class Footer extends React.Component {
  render() {
    const now = new Date();
    return (
      <footer className="footer bg-dark">
        <Row className="align-items-center justify-content-xl-between">
          <Col xl="6">
            <div className="copyright text-center text-xl-left text-white">
              © {now.getFullYear()}{" "}
              <a
                className="font-weight-bold ml-1"
                href="https://www.evacommerce.com.br"
                rel="noopener noreferrer"
                target="_blank"
                style={{ color: "#3ddc51" }}
              >
                Agência Eva
              </a>
            </div>
          </Col>

          <Col xl="6">
            <Nav className="justify-content-center justify-content-xl-end">
              <NavItem>
                <a
                  href="https://www.evacommerce.com.br"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link-footer mr-2"
                >
                  Agência Eva
                </a>
              </NavItem>

              <NavItem>
                <a
                  href="https://www.instagram.com/agencia.eva/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link-footer mr-2"
                >
                  Instagram
                </a>
              </NavItem>

              <NavItem>
                <a
                  href="http://blog.creative-tim.com?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link-footer mr-2"
                >
                  Blog
                </a>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
