import React from 'react';
import ReactJson from 'react-json-view';
import moment from "moment"

export default function JsonViewer({ json }) {
    return (
        <ReactJson
            src={json}
            enableEdit={false}
            enableDelete={false}
            theme="isotope"
            displayDataTypes={false}
            collapsed
            name={moment(json.registrationDate).utc().format("DD/MM/YYYY HH:mm:ss")}
        />
    )
}
