import React from "react";
import { PacmanLoader } from "react-spinners";
import { Modal } from "reactstrap";

import "./styles.scss"

export default function Loading() {
  return (
    <Modal className="loading-modal" isOpen={true}>
      <PacmanLoader color="#3ddc51" />
    </Modal>
  );
}
