import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import auth from "config/auth";

import AdminLayout from "layouts/Admin.jsx";
import Login from "views/Login";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth() ? (
        <Component {...props} />
      ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    }
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={props => <Login {...props} />} />
      <PrivateRoute path="/admin" component={props => <AdminLayout {...props} />} />
      <Redirect from="/admin" to="/admin/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
