import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import Header from "components/Header";
import Loading from "components/Loading";

import api from "config/api";
import './styles.scss';

export default function Systems() {
  const history = useHistory();
  const [systems, setSystems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchSystems();
  }, [])

  async function fetchSystems() {
    setLoading(true);
    try {
      const { data } = await api.get('collection');

      setSystems(data.data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        history.push('/');
      } else {
        console.log(error.response.data.message)
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Header />
      {loading && <Loading />}
      {/* Page content */}
      <Container className="mt--7 bg-dark" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-gradient-dark border border-success">
              <CardHeader className="bg-transparent text-white">Aplicações</CardHeader>
              <CardBody>
                <div className="systems-list" style={{ color: "#3ddc51" }}>
                  <ul>
                    {systems && systems.map(system => (
                      <li key={system}>
                        <Link to={`/admin/logs/${system}`}>{system}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
