import Index from "views/admin/Systems";
import Logs from "views/admin/Logs";

var routes = [
    {
        path: "/index",
        name: "Sistemas",
        icon: "ni ni-tv-2 text-success",
        component: Index,
        layout: "/admin"
    },
    {
        path: "/logs/:collection",
        name: "Logs",
        invisible: true,
        component: Logs,
        layout: "/admin"
    },
 ];
export default routes;