import axios from "axios";

const api = axios.create({
    baseURL: 'https://www.sistema-de-logs-api.plataformaeva.com/'
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `bearer ${token}`;
    }
    return config;
});

export default api;